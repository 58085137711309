import {captureSentryException, initSentry} from '../../@shared/ts/sentry';
import {PLUGIN_VERSION} from '../../git';
import {type PeachPaySDK, peachpaySDK} from './sdk-core';

type PeachPayButtonData = {
	add_to_cart_url: string;

	express_checkout_url: string;
	express_checkout_fragment: string;

	translations: {
		add_product_fail: string;
	};

	// This is used to determine if the checkout iframe should be loaded lazily or eagerly. Defaults to "lazy".
	load_mode?: 'lazy' | 'eager';
};

declare global {
	interface Window {
		peachpay_button: PeachPayButtonData;
		peachpay: PeachPaySDK;
	}
}

initSentry(`peachpay-checkout@${PLUGIN_VERSION}`, 'https://39b5a2e17e264bb5a6ea5abe9bc6cf61@o470066.ingest.sentry.io/5660513');

function main() {
	try {
		if (!window.peachpay_button) {
			console.warn('PeachPay express checkout not initialized. Missing button configuration.');
			return;
		}

		window.peachpay = peachpaySDK({
			checkoutURL: window.peachpay_button.express_checkout_url,
			checkoutContainerFragment: window.peachpay_button.express_checkout_fragment,
			errorReporter: reportError,
			checkoutLoadMode: window.peachpay_button.load_mode,
		});

		window.peachpay.activateButtons();

		// This is the logic used by PeachPay product page buttons. The button needs to submit the "add to cart" form using ajax
		// before opening the PeachPay checkout.
		let $peachpayButtonClicked: HTMLButtonElement | null = null;
		document.addEventListener('click', e => {
			$peachpayButtonClicked = (e.target as HTMLElement)?.closest('button[type="submit"][data-peachpay-button="true"][data-activation-trigger="manual"]');
		});
		document.addEventListener('submit', async e => {
			const $form = e.target as HTMLFormElement;

			// We assign the button here so if a new click event is triggered we do not loose our reference to our button.
			const $button = $peachpayButtonClicked;
			if (!$button) {
				return;
			}

			e.preventDefault();

			if ($button.classList.contains('pp-loading')) {
				return;
			}

			$button.classList.add('pp-loading');

			const formData = new FormData($form);

			if (!formData.has('add-to-cart')) {
				formData.set('add-to-cart', $form.querySelector<HTMLInputElement>('[name="add-to-cart"]')?.value ?? '');
			}

			const response = await fetch(window.peachpay_button.add_to_cart_url, {
				method: 'POST',
				body: formData,
			}).catch(() => null);

			if (response?.ok) {
				document.body.dispatchEvent(new CustomEvent('wc_fragment_refresh'));
				await window.peachpay.showCheckout().catch(reportError);
			} else {
				// eslint-disable-next-line no-alert
				alert(window.peachpay_button.translations.add_product_fail);
			}

			$button.classList.remove('pp-loading');
		});

		// This is the logic used by PeachPay "Add to cart" shortcodes and Elementor widget buttons. They sometimes need
		// to add a product to the cart and this code shows the checkout after the product is added.
		document.addEventListener('click', async e => {
			const $anchor = (e.target as HTMLElement)?.closest<HTMLAnchorElement>('a[data-peachpay-button="true"][data-activation-trigger="shortcode"]');
			if (!$anchor) {
				return;
			}

			e.preventDefault();

			if ($anchor.classList.contains('pp-loading')) {
				return;
			}

			$anchor.classList.add('pp-loading');

			// Note: In the future a optimization could be made here to redirect the actual iframe to this URL. This should not happen until we server
			// render the majority of the express checkout to avoid loading artifacts.
			const response = await fetch($anchor.href, {method: 'GET'}).catch(() => null);

			if (response?.ok) {
				document.body.dispatchEvent(new CustomEvent('wc_fragment_refresh'));
				await window.peachpay.showCheckout().catch(reportError);
			} else {
				// eslint-disable-next-line no-alert
				alert(window.peachpay_button.translations.add_product_fail);
			}

			$anchor.classList.remove('pp-loading');
		});

		// When the PeachPay checkout iframe is closed the host page might need redirected, reloaded or refreshed. Here
		// we define the callback to handle that scenario.
		window.peachpay.onClose(flags => {
			if (flags.redirect) {
				window.location.href = flags.redirect;
				return;
			}

			if (flags.reload) {
				window.location.reload();
				return;
			}

			if (flags.refresh) {
				// Note: "update_checkout" only works on the checkout page. "wc_fragment_refresh" works on all
				// other pages. We do not need to check which page we are on because triggering the event on the
				// wrong page will do nothing. Yay for simplicity!
				document.body.dispatchEvent(new CustomEvent('wc_fragment_refresh'));
				document.body.dispatchEvent(new CustomEvent('update_checkout'));
			}
		});
	} catch (e: unknown) {
		reportError(e);
	}
}

/**
 * This function is used to report error's to the console and to Sentry.
 */
function reportError(e: unknown) {
	if (e instanceof Error) {
		captureSentryException(e);
	}

	console.error(e);
}

main();
