import '../scss/sdk.scss';
import {expressCheckoutInstance} from './sdk-checkout';

interface SDKOptions {
	/**
	 * The URL to the Express Checkout iframe
	 */
	checkoutURL: string;

	/**
	 * The container fragment for the Express Checkout iframe
	 */
	checkoutContainerFragment: string;

	/**
	 * Controls if the Express Checkout iframe is loaded on page load or when the button is clicked. Defaults to "lazy" or when the button is clicked.
	 */
	checkoutLoadMode?: 'eager' | 'lazy';

	/**
	 * Error reporter function. This function is called when an error occurs in the SDK.
	 */
	errorReporter: (error: unknown) => void;
}

/**
 * SDK to control adding, manipulating, and removing the PeachPay Express Checkout buttons
 */
function peachpaySDK(config: SDKOptions) {
	if (!config.checkoutURL) {
		throw new Error('Integration error. A Express Checkout URL must be supplied.');
	}

	const sdkContext = {
		checkoutURL: config.checkoutURL,
		checkoutContainerFragment: config.checkoutContainerFragment,
		checkoutLoadMode: config.checkoutLoadMode ?? 'lazy',
		errorReporter: config.errorReporter,
	} satisfies SDKOptions;

	const checkoutAPI = expressCheckoutInstance(sdkContext);

	return {
		/**
		 * Activates all PeachPay buttons on the page that do not have the `data-activation-trigger` attribute defined. If the attribute is defined then the button is activated by alternative means.
		 */
		activateButtons() {
			document.addEventListener('click', async event => {
				const $target = event.target as HTMLElement;
				if (!$target) {
					return;
				}

				const $button = $target?.closest<HTMLButtonElement>('[data-peachpay-button]');
				if (!$button || $button?.dataset['activationTrigger']) {
					return;
				}

				event.preventDefault();

				if ($button.classList.contains('loading')) {
					return;
				}

				$button.classList.add('loading');
				checkoutAPI.show();
				$button.classList.remove('loading');

				await checkoutAPI.maybeMount().catch(config.errorReporter);
			});
		},
		async showCheckout(): Promise<void> {
			checkoutAPI.show();
			await checkoutAPI.maybeMount().catch(config.errorReporter);
		},
		hideCheckout: checkoutAPI.hide,
		onClose: checkoutAPI.onClose,
	};
}

type PeachPaySDK = ReturnType<typeof peachpaySDK>;

export {
	type SDKOptions,
	type PeachPaySDK,

	peachpaySDK,
};
