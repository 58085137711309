// IOS Safari/Desktop Safari browsers < 10.3
import 'url-search-params-polyfill';
import 'whatwg-fetch';
import 'formdata-polyfill';
import ResizeObservePollyFill from 'resize-observer-polyfill';

window.ResizeObserver = window.ResizeObserver || ResizeObservePollyFill;

Promise.allSettled = Promise.allSettled || async function (promises: Array<Promise<unknown>>) {
	const mappedPromises = promises.map(async p => p
		.then(value => ({
			status: 'fulfilled',
			value,
		}))
		.catch((reason: unknown) => ({
			status: 'rejected',
			reason,
		})));
	return Promise.all(mappedPromises);
};

declare global {
	interface FormData {
		// Not sure why this is needed, but it is -\_(o_o)_/-
		entries(): IterableIterator<[string, string]>;
	}
}
